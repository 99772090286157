<template>
  <LayoutNew>
    <MDBCard id="CollectionsAssetsComponent" fluid>
      <MDBCardHeader class="p-4">
        <template v-if="isLoading">
          <div class="d-flex justify-content-center align-items-center">
            <MDBSpinner />
          </div>
        </template>
        <template v-else>
          <div class="header-container">
            <MDBCardTitle class="my-auto" tag="h2" :title="assetInfo.title">{{ assetInfo.title }}
            </MDBCardTitle>
            <div class="actions">
              <!--<MDBDropdown v-model="dropdown">
                    <MDBDropdownToggle
                      outline="tertiary"
                      @click.stop.prevent="dropdown = !dropdown"
                      >Action
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem href="#">Action 1 </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                  <MDBBtn outline="tertiary"> Copies </MDBBtn>-->
              <MDBBtn color="primary" size="sm" type="button" @click="toggleAssetStatus">{{
                toggleAssetStatusText }}
              </MDBBtn>
            </div>
          </div>
        </template>
      </MDBCardHeader>
      <MDBCardBody class="p-4 pb-0">
        <template v-if="isLoading">
          <div class="d-flex justify-content-center align-items-center mb-4">
            <MDBSpinner />
          </div>
        </template>
        <template v-else>
          <MDBAlert class="m-auto mb-4" color="warning" static v-if="!assetInfo.isTracked">
            <i class="fas fa-exclamation-triangle me-3"></i>
            You're not tracking this asset. Turn on tracking to start discovering
            copies.
          </MDBAlert>
          <div class="align-items-start d-md-flex d-sm-block gap-4">
            <div class="shadow-lg mb-sm-4 rounded-5">
              <MDBCard>
                <MDBCardBody>
                  <img :src="assetInfo.thumbnailUrl" class="img-fluid" alt="thumbnailUrl" />
                  <div class="info-container ps-2">
                    <MDBCardTitle>Source Information</MDBCardTitle>
                    <MDBCardText class="info">
                      <span>Platform</span>
                      <span>Pexoso</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Production Date</span>
                      <span>{{ parseDateLong(assetInfo.productionDate) }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Duration</span>
                      <span>{{ secondsToTime(assetInfo.duration) }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Media Type</span>
                      <span>{{ assetInfo.mediaType }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Music Video</span>
                      <span>No</span>
                    </MDBCardText>
                  </div>
                  <div class="info-container ps-2">
                    <MDBCardTitle>Basic Information</MDBCardTitle>
                    <MDBCardText class="info">
                      <span>Date Added</span>
                      <span>{{ parseDateLong(assetInfo.createdAt) }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Last Edited</span>
                      <span>{{ parseDateLong(assetInfo.updatedAt) }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Tracked From</span>
                      <span>{{ parseDateLong(assetInfo.trackedFrom) }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Added By</span>
                      <span>—</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Collections</span>
                      <span>{{ assetInfo.collections.length }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Reference ID</span>
                      <span>—</span>
                    </MDBCardText>
                  </div>
                  <div class="info-container ps-2">
                    <MDBCardTitle>Audio Recording Information</MDBCardTitle>
                    <MDBCardText class="info">
                      <span>Album</span>
                      <span>{{
                        assetInfo.music.album ? assetInfo.music.album : "—"
                      }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>Artist</span>
                      <span>{{
                        assetInfo.music.artist ? assetInfo.music.artist : "—"
                      }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>ISRC</span>
                      <span>{{
                        assetInfo.music.isrcs.length != 0
                          ? assetInfo.music.isrcs.length
                          : "—"
                      }}</span>
                    </MDBCardText>
                    <MDBCardText class="info">
                      <span>ISWC</span>
                      <span>{{
                        assetInfo.music.iswcs.length != 0
                          ? assetInfo.music.iswcs.length
                          : "—"
                      }}</span>
                    </MDBCardText>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div class="d-block gap-4">
              <MDBCard class="statistic-card shadow-lg mb-sm-4">
                <MDBCardHeader>Asset Statistics</MDBCardHeader>
                <MDBCardBody>
                  <div>
                    <MDBCardTitle>Unable to display statistics</MDBCardTitle>
                    <MDBCardText>Statistics for this Asset cannot currently be
                      displayed</MDBCardText>
                  </div>
                </MDBCardBody>
              </MDBCard>
              <MDBCard class="statistic-card shadow-lg">
                <MDBCardHeader>Asset History</MDBCardHeader>
                <MDBCardBody>
                  <MDBCardTitle>Nothing to display</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </template>
      </MDBCardBody>
    </MDBCard>
    <!-- toast -->
    <MDBToast v-model="toast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
      color="success" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Success </template>
      Status Changed Successfully
    </MDBToast>
  </LayoutNew>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardHeader,
  MDBCardText,
  MDBSpinner,
  MDBBtn,
  MDBAlert,
  MDBToast,
} from "mdb-vue-ui-kit";
import LayoutNew from "@/views/v2/LayoutNew.vue";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { GetAsset } from "@/services/Assets/GetAsset";
import { parseDateLong } from "@/helpers/parseDate";
import { PutAssetTrackingStatus } from "@/services/Assets/PutAssetTrackingStatus";
import { useTitle } from "@vueuse/core";

const assetInfo = ref({});
const isLoading = ref(true);
const route = useRoute();
onMounted(async () => {
  assetInfo.value = await GetAsset(route.params.assetId);
  useTitle(`${assetInfo.value.title} | CreatorShield`);
  isLoading.value = false;
});

const secondsToTime = (e) => {
  const h = Math.floor(e / 3600)
    .toString()
    .padStart(2, "0"),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

  return `${h}:${m}:${s}`;
};
// const dropdown = ref(false);
const toggleAssetStatus = async () => {
  isLoading.value = true;
  await PutAssetTrackingStatus(assetInfo.value.assetIdString, {
    IsTracked: !assetInfo.value.isTracked,
  });
  toast.value = true;
  assetInfo.value = {
    ...assetInfo.value,
    isTracked: !assetInfo.value.isTracked,
  };
  isLoading.value = false;
};

const toggleAssetStatusText = computed(() => {
  return assetInfo.value.isTracked ? "Untrack this asset" : "Track this asset";
});

const toast = ref(false);
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}

.statistic-card {
  margin-bottom: 0.75rem;
}

.statistic-card .card-header {
  font-weight: bold;
  font-size: 20px;
}

.statistic-card .card-body {
  height: 200px;
  display: grid;
  place-items: center;
}

.info-container {
  padding: 20px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: flex;
}

.actions .solid {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.actions .solid:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}
</style>